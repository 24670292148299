import SiteButton_ButtonShadowLeftComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/BaseButton/ButtonShadowLeft.skin';


const SiteButton_ButtonShadowLeft = {
  component: SiteButton_ButtonShadowLeftComponent
};


export const components = {
  ['SiteButton_ButtonShadowLeft']: SiteButton_ButtonShadowLeft
};


// temporary export
export const version = "1.0.0"
